// app/providers.tsx
"use client";

import { NextUIProvider } from "@nextui-org/react";
import { TRPCReactProvider } from "@/trpc/react";
import {
  EnvironmentProvider,
  useEnvironment,
} from "@/providers/app/EnvironmentProvider";
import React, { createContext, useContext, useMemo } from "react";
import axios, { AxiosInstance } from "axios";
import { ENVIRONMENTS } from "@/providers/app/Environment/environments";
import {
  FacebookPageView,
  FacebookTrackingProvider,
} from "next-facebook-tracking/components";
import { facebook } from "@/constants/landingPage/fbPixel";

interface LandingPageAppStateProviderProps {
  children: React.ReactNode;
}

interface LandingPageAppState {
  axiosInstance: AxiosInstance;
}

const LandingPageAppStateContext = createContext<
  LandingPageAppState | undefined
>(undefined);

export const LandingPageAppStateProvider = ({
  children,
}: LandingPageAppStateProviderProps) => {
  const { API_URL } = useEnvironment();

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: API_URL,
    });
  }, [API_URL]);

  return (
    <LandingPageAppStateContext.Provider
      value={{
        axiosInstance,
      }}>
      {children}
    </LandingPageAppStateContext.Provider>
  );
};

export function useLandingPageAppState(): LandingPageAppState {
  const context = useContext<any>(LandingPageAppStateContext);

  if (!context) {
    throw Error(
      "useLandingPageAppState must be used within a LandingPageAppStateContext"
    );
  }
  return context;
}

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <EnvironmentProvider defaultEnv={ENVIRONMENTS.PROD}>
      <NextUIProvider>
        <TRPCReactProvider>
          <LandingPageAppStateProvider>{children}</LandingPageAppStateProvider>
        </TRPCReactProvider>
      </NextUIProvider>
    </EnvironmentProvider>
  );
}
