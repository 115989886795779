import {
  defaultShouldDehydrateQuery,
  QueryClient,
} from "@tanstack/react-query";
import SuperJSON from "superjson";

const defaultConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // staleTime: 150,
      retry: 1,
      staleTime: 30 * 1000,
    },

    dehydrate: {
      serializeData: SuperJSON.serialize,
      shouldDehydrateQuery: (query) => {
        console.log("should hydrate: ", query);
        return (
          defaultShouldDehydrateQuery(query) || query.state.status === "pending"
        );
      },
    },
    hydrate: {
      deserializeData: SuperJSON.deserialize,
    },
  },
};

export const queryClient = new QueryClient(defaultConfig);

export const createQueryClient = () => {
  return new QueryClient(defaultConfig);
};
